$("#cookie_consent_accept").click(function() {
	setCookie("cookie_consent", 1, 365);
});

function setCookie(cname, cvalue, exdays) {
	var d = new Date();
	d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
	var expires = "expires="+ d.toUTCString();
	document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

var signup_form_password_check = function() {
	if (document.getElementById('register_password').value.length >= 8) {
		document.getElementById('password_character_count').style.color = '#50B121';
		document.getElementById("register_confirm_password").disabled = false;
	} else {
		document.getElementById('password_character_count').style.color = '#FF0000';
		document.getElementById("register_confirm_password").disabled = true;
	}
}

var match_check = function() {
	if (document.getElementById('register_password').value == document.getElementById('register_confirm_password').value) {
		document.getElementById('message').style.color = '#50B121';
	} else {
		document.getElementById('message').style.color = '#FF0000';
	}

	if (
		(
			(document.getElementById('register_password').value.length >= 8) &&
			(document.getElementById('register_password').value == document.getElementById('register_confirm_password').value)
		) &&
		(document.getElementById('register_agree_to_terms').checked == true)
	) {
		document.getElementById("signup_button").disabled = false;
	} else {
		document.getElementById("signup_button").disabled = true;
	}
}